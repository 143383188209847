import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { replace } from "lodash";
import numeral from "numeral";
import { format, formatDistanceToNow } from "date-fns";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

export const n6 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6
});
export const n4 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 4
});

export const c2 = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

export const tokenValue = (value: number, decimals: number) => (decimals ? value / Math.pow(10, decimals) : value);

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */
export const tokenValueTxt = (value: number, decimals: number, symbol: string) =>
  `${n4.format(tokenValue(value, decimals))} ${symbol}`;

export function parseBigNumberToFloat(val: BigNumber, decimals = 18) {
  if (!val) {
    return 0;
  }

  const formatted = formatUnits(val, decimals);
  const parsed = parseFloat(formatted);
  return parsed;
}




TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

// Number ----------------------------------------------------------------------

export function fCurrency(number: number, fix: number) {
  try {
    let str = number + "";
    if (str.indexOf("e") >= 0) {
      str = number.toFixed(8) + "";
    }
    const deleteText = str.replace(/[^\d.]/g, ""); //clear text
    const x = deleteText.split(".");
    let x1 = x[0];
    const x2 = x[1];
    const x3 = x.length > 1 ? "." + x2.slice(0, fix || 8) : "";
    if (!x1) x1 = "0";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1,$2");
    }
    let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
    return `${number < 0 ? "-" : ""}${result}`;
  } catch (e) {
    return "0.00";
  }
}

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fShortenNumber(number: number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number: number) {
  return numeral(number).format("0.0 b");
}

export function fRoundDown(number: number, decimals: number) {
  decimals = decimals || 0;
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

// Date time ----------------------------------------------------------------------

export function fDate(date: any) {
  try {
    return format(new Date(date), "dd MMMM yyyy");
  } catch (error) {
    return 'unknown'
  }
}

export function fDateTime(date: any) {
  try {
    return format(new Date(date), "yyyy-MM-dd HH:mm");
  } catch (error) {
    return 'unknown'
  }
}

export function fDateTimeSuffix(date: any) {
  try {
    return format(new Date(date), "dd/MM/yyyy hh:mm p");
  } catch (error) {
    return 'unknown'
  }
}

export function fToNow(date: any) {
  try {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
    });
  } catch (error) {
    return 'unknown'
  }
}

export function fTimeAgo(time: any) {
  return timeAgo.format(time);
}

// String ----------------------------------------------------------------------

export function fDisplayName(displayName = "") {
  const length = displayName.length;
  if (length > 15) {
    return `${displayName.substring(0, 5)}...${displayName.substring(
      length - 5,
      length
    )}`;
  }
  return displayName;
}

export const fAddress = (string: string, length = 4) => {
  if (string) {
    if (string.length > length * 2) {
      return (
        string.slice(0, length) + "..." + string.slice(string.length - length)
      );
    } else {
      return string;
    }
  } else return null;
};
