import {
  Box,
  ButtonBase,
  styled,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import Iconify from "components/Iconify";
import { FC, Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import LoadingScreen from "./InitLoading";
import useAxios from "hooks/useAxios";
import { fCurrency } from "utils/formatters";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  transition: "all 0.3s",
  position: "relative",
  minHeight: "100vh",
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginLeft: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 40px)',
    marginLeft: '20px'
  },
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 0,
  [theme.breakpoints.up("lg")]: { maxWidth: "100%", margin: "auto" },
}));
const BorderTopLeft = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  paddingTop: "1rem",
  paddingLeft: "1rem",
  width: "50vw",
  height: "50vh",
  clipPath: "url(#border-top-left)",
  zIndex: 10,
  boxShadow: "inset 20px 0px 50px #000, inset 0px 50px 50px #000",
}));
const BorderTopRight = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  width: "50vw",
  height: "50vh",
  clipPath: "url(#border-top-right)",
  zIndex: 1,
  boxShadow: "inset -20px 0px 50px #000, inset 0px 50px 50px #000",
}));
const BorderBottomLeft = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "50vw",
  height: "50vh",
  paddingBottom: "1rem",
  paddingLeft: "1rem",
  clipPath: "url(#border-bottom-left)",
  zIndex: 9,
  boxShadow: "inset 20px 0px 50px #000, inset 0px -50px 50px #000",
}));

const BorderBottomRight = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  right: 0,
  width: "50vw",
  height: "50vh",
  clipPath: "url(#border-bottom-right)",
  zIndex: 1,
  boxShadow: "inset -20px 0px 50px #000, inset 0px -50px 50px #000",
}));

const SummaryBox = styled(FlexRowAlign)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  zIndex: 12,
  width: "100vw",
  height: 40,
  [theme.breakpoints.down('md')]: {
    background: theme.palette.background.default,

  }
}));

const MediaBox = styled(FlexRowAlign)(({ theme }) => ({
  position: "fixed",
  bottom: 10,
  right: 20,
  zIndex: 12,
  height: 40,
  [theme.breakpoints.down('md')]: {
    top: 15
  }
}));

// --------------------------------------------
type LayoutBodyWrapperProps = {
  sx?: SxProps;
  children: ReactNode;
};
// --------------------------------------------

const LayoutBodyWrapper: FC<LayoutBodyWrapperProps> = ({ children, sx }) => {
  const theme = useTheme();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth / 2);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setInnerWidth(window.innerWidth / 2);
      setInnerHeight(window.innerHeight);
    });
    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);
  const handleClickMedia = (url: string) => {
    window.open(url, "_black");
  };
  const { callApi} = useAxios();
 
  const [data ,setData] = useState()
  const fetchData = useCallback(async ()=>{
    const reps = await callApi('get','dashboard/internal-info')
    setData(reps as any)
  },[])
  useEffect(()=>{
    fetchData()
  },[fetchData])

  console.log(data)
  return (
    <Fragment>
      <Wrapper sx={sx}>
        <BorderTopLeft sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/static/layouts/box-top-left.svg"
            style={{ height: 400 }}
            alt="box-top-left"
          />
          <svg
            width={0}
            height={0}
            viewBox={`0 0 ${innerWidth} ${innerHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <clipPath id="border-top-left">
              <path
                d={`M50 90V${innerHeight}H-1.5V-0.5H${innerWidth}V90H50Z`}
              />
            </clipPath>
          </svg>
        </BorderTopLeft>
        <BorderTopRight sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/static/layouts/box-top-right.svg"
            style={{
              height: 200,
              position: "absolute",
              top: "calc(1rem + 2px)",
              right: "1rem",
            }}
            alt="box-top-right"
          />
          <svg
            width={0}
            height={0}
            viewBox={`0 0 ${innerWidth} ${innerHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <clipPath id="border-top-right">
              <path
                d={`M${innerWidth} -5H-3.5V90H${innerWidth - 50
                  }V${innerHeight}H${innerWidth}V-5Z`}
              />
            </clipPath>
          </svg>
        </BorderTopRight>
        <InnerWrapper>{children}</InnerWrapper>
        <BorderBottomLeft sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/static/layouts/box-bottom-left.svg"
            style={{
              height: 430,
              position: "absolute",
              bottom: "1rem",
              left: "1rem",
              zIndex: 10,
            }}
            alt="box-bottom-left"
          />
          <svg
            width={0}
            height={0}
            viewBox={`0 0 ${innerWidth} ${innerHeight / 2}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <clipPath id="border-bottom-left">
              <path
                d={`M0 ${innerHeight / 2}V0H50V${innerHeight / 2 - 90
                  }H${innerWidth}V${innerHeight / 2 + 10}H10Z`}
              />
            </clipPath>
          </svg>
        </BorderBottomLeft>
        <BorderBottomRight sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/static/layouts/box-bottom-right.svg"
            style={{
              height: 350,
              position: "absolute",
              bottom: "1rem",
              right: "1rem",
            }}
            alt="box-bottom-right"
          />
          <svg
            width={0}
            height={0}
            viewBox={`0 0 ${innerWidth} ${innerHeight / 2}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <clipPath id="border-bottom-right">
              <path
                d={`M${innerWidth} ${innerHeight / 2}H0V${innerHeight / 2 - 90
                  }H${innerWidth - 50}V0H${innerWidth}V${innerHeight / 2}Z`}
              />
            </clipPath>
          </svg>
        </BorderBottomRight>
      </Wrapper>
      <SummaryBox>
        <FlexBetween gap={5}>
          {/* <Typography
            variant="caption"
            fontWeight={700}
            textTransform={"uppercase"}
            fontSize={{ xs: '0.6rem', md: '0.85rem' }}
          >
            TVL / $1,411,770,277
          </Typography> */}
          <Typography
            variant="caption"
            fontWeight={700}
            textTransform={"uppercase"}
            fontSize={{ xs: '0.6rem', md: '0.85rem' }}
          >
            Total Users / {fCurrency((data as any)?.totalAddress ?? 0,4)}
          </Typography>
          <Typography
            variant="caption"
            fontWeight={700}
            textTransform={"uppercase"}
            fontSize={{ xs: '0.6rem', md: '0.85rem' }}
          >
            Airdrop Live
          </Typography>
        </FlexBetween>
      </SummaryBox>
      <MediaBox gap={1}>
        <ButtonBase onClick={() => handleClickMedia("https://x.com/flaex_io")}>
          <Iconify
            icon="hugeicons:new-twitter"
            size={22}
            sx={{ color: theme.palette.text.primary }}
          />
        </ButtonBase>
        <ButtonBase
          onClick={() => handleClickMedia("https://discord.gg/flaex")}
        >
          <Iconify
            icon="ri:discord-fill"
            size={25}
            sx={{ color: theme.palette.text.primary }}
          />
        </ButtonBase>
        <ButtonBase onClick={() => handleClickMedia("https://docs.flaex.io")}>
          <img
            src="/static/svg/git-book.svg"
            style={{ width: 28, height: 28 }}
            alt="git-book"
          />
        </ButtonBase>
        <ButtonBase onClick={() => handleClickMedia("https://t.me/flaex_io")}>
          <Iconify
            icon="uil:telegram"
            size={25}
            sx={{ color: theme.palette.text.primary }}
          />
        </ButtonBase>
        <ButtonBase>
          <Iconify
            icon="flowbite:github-solid"
            size={25}
            sx={{ color: theme.palette.text.primary }}
          />
        </ButtonBase>
      </MediaBox>
      <LoadingScreen />
    </Fragment>
  );
};

export default LayoutBodyWrapper;
